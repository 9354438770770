import { NgModule                                } from '@angular/core';
import { CommonModule                            } from '@angular/common';

import { AppCommonModule                         } from 'app/common/common.module';
import { TranslationModule                       } from 'app/core/translate/translate.module';
import { SharedComponentsModule as AppComponentsModule } from 'app/shared/components/components.module';
import { SharedFormsModule      as AppFormsModule      } from 'app/shared/forms/forms.module';

import { ComponentsModule                        } from './components/components.module';
import { UsersModule                             } from './users/users.module';
import { OrganizationsModule                     } from './organizations/organizations.module';
import { DivisionsModule                         } from './divisions/divisions.module';


@NgModule({
  declarations: [
  ],
  exports: [
    UsersModule,
    OrganizationsModule,
    DivisionsModule
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    TranslationModule,
    AppFormsModule,
    AppComponentsModule,
    ComponentsModule
  ]
})
export class AdminTablesModule { }
