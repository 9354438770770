<app-loading *ngIf="loading" borderRadius="4px"></app-loading>

<h1 mat-dialog-title>
  {{ (addMode ? 'tables.admin-tables.organizations.add-edit.title.add' : 'tables.admin-tables.organizations.add-edit.title.edit') | translate }}
</h1>

<mat-dialog-content>
  <form [formGroup]="form">

    <div class="form-field-wrapper" *ngIf="form.controls.organizationType">
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>{{ 'tables.admin-tables.organizations.add-edit.labels.organization_type' | translate }}</mat-label>
        <mat-select formControlName="organizationType" class="with-icon">
          <mat-select-trigger>
            <mat-icon>{{ form.controls.organizationType!.value == 'school' ? 'school' : 'sports_soccer' }}</mat-icon>
            {{ form.controls.organizationType!.value | organizationType }}
          </mat-select-trigger>
          <mat-option *ngFor="let x of organizationTypes" [value]="x">
            <mat-icon>{{ x == 'school' ? 'school' : 'sports_soccer' }}</mat-icon>
            {{ x | organizationType }}
          </mat-option>
        </mat-select>
        <mat-error><app-form-field-error [control]="form.controls.organizationType"></app-form-field-error></mat-error>
      </mat-form-field>
    </div>

    <div class="form-field-wrapper">
      <mat-form-field class="form-field" appearance="outline" style="position:relative; z-index:2">
        <mat-label class="form-label">{{ 'tables.admin-tables.organizations.add-edit.labels.name' | translate | translate }}</mat-label>
        <input matInput
          formControlName="name"
          (ngModelChange)="triggerAutocomplete({ Skolenhetsnamn: $event })"
          [matAutocomplete]="auto"
        >
        <mat-autocomplete #auto="matAutocomplete" class="virtual-scroll" (optionSelected)="optionSelected($event)">
          <!-- the min and max buffer sizes are important as otherwise the rendering is laggy and the last item sometimes missing -->
          <cdk-virtual-scroll-viewport
            itemSize="48" minBufferPx="1000" maxBufferPx="1000"
            class="dark-vertical-scrollbar"
            [style.height.px]="((filteredSchools | async)?.length ?? 0) * 48"
          >
            <mat-option *cdkVirtualFor="let x of filteredSchools | async" [value]="x">
              <span [innerHTML]="x.displayName"></span>
            </mat-option>
          </cdk-virtual-scroll-viewport>
        </mat-autocomplete>
        <mat-error><app-form-field-error [control]="form.controls.name"></app-form-field-error></mat-error>
      </mat-form-field>
    </div>

    <div class="form-field-wrapper" *ngIf="form.controls.schoolCode" style="position:relative; z-index:2">
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>{{ 'tables.admin-tables.organizations.add-edit.labels.school_code' | translate }}</mat-label>
        <input matInput
          formControlName="schoolCode"
          (ngModelChange)="triggerAutocomplete({ Skolenhetskod: $event })"
          [matAutocomplete]="auto"
        >
        <mat-autocomplete #auto="matAutocomplete" class="virtual-scroll" (optionSelected)="optionSelected($event)">
          <!-- the min and max buffer sizes are important as otherwise the rendering is laggy and the last item sometimes missing -->
          <cdk-virtual-scroll-viewport
            itemSize="48" minBufferPx="1000" maxBufferPx="1000"
            class="dark-vertical-scrollbar"
            [style.height.px]="((filteredSchools | async)?.length ?? 0) * 48"
          >
            <mat-option *cdkVirtualFor="let x of filteredSchools | async" [value]="x">
              <span [innerHTML]="x.displayName"></span>
            </mat-option>
          </cdk-virtual-scroll-viewport>
        </mat-autocomplete>

        <mat-error><app-form-field-error [control]="form.controls.schoolCode"></app-form-field-error></mat-error>
      </mat-form-field>
    </div>

    <!-- TEMPORARILY DISABLED -->
    <!-- <div class="form-field-wrapper" *ngIf="form.controls.organization">
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>{{ 'tables.admin-tables.organizations.add-edit.labels.organization' | translate }}</mat-label>
        <mat-select formControlName="organization">
          <mat-option *ngFor="let x of organizations" [value]="x.id">
            {{ x.name }}
          </mat-option>
        </mat-select>
        <mat-error><app-form-field-error [control]="form.controls.organization"></app-form-field-error></mat-error>
      </mat-form-field>
    </div> -->

    <div class="form-field-wrapper" *ngIf="form.controls.associatedPartner">
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>{{ 'common.associated_partner' | translate }}</mat-label>
        <mat-select formControlName="associatedPartner" panelClass="image-icons dark-vertical-scrollbar" class="with-icon">
          <mat-select-trigger *ngIf="form.controls.associatedPartner.value; let x">
            <img [src]="partners[x]?.icon"/>
            {{ partners[x]?.name }}
          </mat-select-trigger>
          <mat-option [value]="null">
            {{ 'common.none_singular' | translate }}
          </mat-option>
          <mat-option *ngFor="let x of partners | keyvalue" [value]="x.key">
            <img [src]="x.value?.icon"/>
            {{ x.value?.name }}
          </mat-option>
        </mat-select>
        <mat-error><app-form-field-error [control]="form.controls.associatedPartner"></app-form-field-error></mat-error>
      </mat-form-field>
    </div>

    <div class="form-field-wrapper" *ngIf="form.controls.theme">
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>{{ 'tables.admin-tables.organizations.add-edit.labels.theme' | translate }}</mat-label>
        <mat-select formControlName="theme" panelClass="image-icons dark-vertical-scrollbar" class="with-icon">
          <mat-select-trigger *ngIf="form.controls.theme.value; let x">
            <img [src]="themes[x]?.icon"/>
            {{ themes[x]?.name }}
          </mat-select-trigger>
          <mat-option *ngFor="let x of themes | keyvalue" [value]="x.key">
            <img [src]="x.value?.icon"/>
            {{ x.value?.name }}
          </mat-option>
        </mat-select>
        <mat-error><app-form-field-error [control]="form.controls.theme"></app-form-field-error></mat-error>
      </mat-form-field>
    </div>

    <div class="form-field-wrapper padding-bottom padding-inline" *ngIf="form.controls.canCreateCompanies">
      <mat-checkbox formControlName="canCreateCompanies">
        {{ 'shared.forms.company.organization' | translate }}
      </mat-checkbox>
    </div>

  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close type="button">
    {{ 'common.close' | translate }}
  </button>
  <button mat-button color="primary" type="submit" [disabled]=" ! form.valid" (click)="submit()">
    {{ (addMode ? 'common.create' : 'common.save') | translate }}
  </button>
</mat-dialog-actions>

