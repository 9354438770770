<mat-toolbar class="app-table-toolbar">

  <app-search class="search" placeholder="common.search.users"></app-search>

  <div class="spacer"></div>

  <button mat-mini-fab
    type="button"
    color="primary"
    matTooltip="{{ 'tables.admin-tables.users.actions.add' | translate }}"
    (click)="openAddEditDialog()"
  >
    <mat-icon>add</mat-icon>
  </button>
</mat-toolbar>

<div class="app-table-container">
  <app-loading [loading]="dataSource.loading$ | async"></app-loading>

    <app-fetch-fail-pane [show]="dataSource.error$ | async"></app-fetch-fail-pane>

    <table #table class="header-nowrap"
      mat-table [dataSource]="dataSource"
      matSort matSortActive="createdAt" matSortDirection="desc"
    >

      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'tables.admin-tables.users.columns.username' | translate }}</th>
        <td mat-cell *matCellDef="let element; table: table">{{ element.username }}</td>
      </ng-container>

      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'tables.admin-tables.users.columns.organization' | translate }}</th>
        <td mat-cell *matCellDef="let element; table: table">{{ element.company?.name }}</td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'tables.admin-tables.users.columns.role' | translate }}</th>
        <td mat-cell *matCellDef="let element; table: table">{{ element.role | role }}</td>
      </ng-container>

      <ng-container matColumnDef="associatedPartner">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'common.associated_partner' | translate }}</th>
        <td mat-cell *matCellDef="let element; table: table">
          <app-icon [value]="element.associatedPartner"></app-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="activation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="verifyToken">{{ 'tables.admin-tables.users.columns.activation' | translate }}</th>

        <td mat-cell *matCellDef="let element; table: table">
          <button
            mat-icon-button
            [matTooltip]="'tables.admin-tables.users.actions.copy_verification_link' | translate"
            *ngIf="element.verifyToken"
            (click)="copyVerificationLink(element)"
          >
            <mat-icon>filter_none</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="online">
        <th mat-header-cell *matHeaderCellDef>{{ 'tables.admin-tables.users.columns.online' | translate }}</th>
        <td mat-cell *matCellDef="let element; table: table">
          <mat-icon [ngClass]=" element.online ? 'online' : 'offline'">public</mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="lastLogin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'tables.admin-tables.users.columns.last_login' | translate }}</th>
        <td mat-cell *matCellDef="let element; table: table">
          <span [matTooltip]="element.lastLogin | localeDate">
            {{ now$ | async | durationSince:element.lastLogin | capitalizeFirst }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'common.created_at' | translate }}</th>
        <td mat-cell *matCellDef="let element; table: table">
          <span [matTooltip]="element.createdAt | localeDate">
            {{ now$ | async | durationSince:element.createdAt | capitalizeFirst }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element; table: table">
          <app-table-action-buttons [element]="element" [buttons]="actionButtons"></app-table-action-buttons>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns(); sticky: true"></tr>

      <tr mat-row *matRowDef="let row; columns: columns();"></tr>

    </table>
</div>

@if (showPaginator()) {
  <mat-paginator
    showFirstLastButtons
    [length]="dataSource ? (dataSource.totNumDocs$ | async) : 0"
    [pageIndex]="0"
    [pageSize]="pageSize()"
    [pageSizeOptions]="pageSizes()">
  </mat-paginator>
}