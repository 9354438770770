<mat-toolbar class="app-table-toolbar">

  <app-search class="search" placeholder="common.search.schedules"></app-search>

  <div class="spacer"></div>

  <!-- <button mat-mini-fab
    type="button"
    color="primary"
    matTooltip="{{ 'common.add' | translate }}"
    (click)="openAddDialog()"
  >
    <mat-icon>add</mat-icon>
  </button> -->
</mat-toolbar>

<div class="app-table-container">
  <app-loading [loading]="dataSource.loading$ | async"></app-loading>

  <app-fetch-fail-pane [show]="dataSource.error$ | async"></app-fetch-fail-pane>

  <table #table class="header-nowrap"
    mat-table [dataSource]="dataSource"
    matSort matSortActive="createdAt" matSortDirection="desc"
  >

    <ng-container matColumnDef="organization">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'tables.admin-tables.divisions.actions.columns.organization' | translate }}</th>
      <td mat-cell *matCellDef="let element; table: table">{{ element.company.name }}</td>
    </ng-container>

    <ng-container matColumnDef="displayName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'tables.admin-tables.divisions.actions.columns.displayName' | translate }}</th>
      <td mat-cell *matCellDef="let element; table: table">{{ element.displayName }}</td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'tables.admin-tables.divisions.actions.columns.id' | translate }}</th>
      <td mat-cell *matCellDef="let element; table: table">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="associatedPartner">
      <th mat-header-cell *matHeaderCellDef>{{ 'common.associated_partner' | translate }}</th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-icon [value]="element.associatedPartner"></app-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'common.created_at' | translate }}</th>
      <td mat-cell *matCellDef="let element; table: table">
        <span [matTooltip]="element.createdAt | localeDate">
          {{ now$ | async | durationSince:element.createdAt | capitalizeFirst }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="editedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'common.edited_at' | translate }}</th>
      <td mat-cell *matCellDef="let element; table: table">
        <span [matTooltip]="element.editedAt | localeDate">
          {{ now$ | async | durationSince:element.editedAt | capitalizeFirst }}
        </span>
    </ng-container>

    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef class="shrink"></th>
      <td mat-cell *matCellDef="let element; table: table" class="shrink">
        <app-table-action-buttons [element]="element" [buttons]="actionButtons()"></app-table-action-buttons>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns(); sticky: true"></tr>

    <tr mat-row *matRowDef="let row; columns: columns();"></tr>

  </table>
</div>

@if (showPaginator()) {
  <mat-paginator
    showFirstLastButtons
    [length]="dataSource ? (dataSource.totNumDocs$ | async) : 0"
    [pageIndex]="0"
    [pageSize]="pageSize()"
    [pageSizeOptions]="pageSizes()">
  </mat-paginator>
}