<h2 mat-dialog-title>{{ 'tables.admin-tables.organizations.app_features.title' | translate }}</h2>

<mat-dialog-content>
  <section>
    <mat-slide-toggle [formControl]="form.controls.substitutionPlanner.controls.enabled">
      {{ 'tables.admin-tables.organizations.app_features.substitution_planner' | translate }}
    </mat-slide-toggle>
  </section>

  <section>
    <mat-slide-toggle [formControl]="form.controls.statisticsModule.controls.enabled">
      {{ 'tables.admin-tables.organizations.app_features.statistics_module' | translate }}
    </mat-slide-toggle>

    <section>
      <mat-slide-toggle [formControl]="form.controls.statisticsModule.controls.scb">
        {{ 'view.private.schedule.statistics.scb.title' | translate }}
      </mat-slide-toggle>

      <mat-slide-toggle [formControl]="form.controls.statisticsModule.controls.lunchCongestion">
        {{ 'view.private.schedule.statistics.lunch_congestion.title' | translate }}
      </mat-slide-toggle>
    </section>
  </section>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close type="button">
    {{ 'common.close' | translate }}
  </button>
  <button mat-button color="primary" type="submit"
    [disabled]="! form.valid || form.pristine"
    (click)="submit()"
  >
    {{ 'common.save' | translate }}
  </button>
</mat-dialog-actions>