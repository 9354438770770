import { Component,
         Inject,
         OnDestroy                        } from '@angular/core';
import { FormControl,
         FormGroup,
         Validators                       } from '@angular/forms';
import { MatAutocompleteSelectedEvent     } from '@angular/material/autocomplete';
import { BehaviorSubject,
         Subject,
         combineLatest,
         debounceTime,
         distinctUntilChanged,
         filter,
         fromEvent,
         map,
         skip,
         startWith,
         takeUntil                        } from 'rxjs';
import $                                    from 'jquery';

import { MAT_DIALOG_DATA,
         MatDialogRef,
         Util                             } from 'app/common';
import { AuthService,
         EnvironmentService,
         HttpService,
         LoggerService,
         PushNotificationService          } from 'app/core';
import { Partner,
         VIEW_ACCESS_TYPE,
         ViewAccessType,
         apiConstants                     } from 'app/constants';
import { Company                          } from 'app/shared/interfaces';
import { OrganizationType,
         Theme                            } from 'app/core/environment/environment.types';
import { Integrations, organizationTypes                     } from 'app/core/environment/environment.constants';
import { sortBy, escapeRegExp } from 'lodash';


export type Organization = Pick<Company.complete, 'id' | 'name' | 'environment' | 'associatedPartner'>;

export type Data = {
  urlRootDir:    typeof apiConstants.ADMIN | typeof apiConstants.PARTNER;
  organization?: Organization;
}


type ThemesRecord = Record<Theme, { name?: string; icon?: string }>;
const themesRecord: ThemesRecord = {
  'royal_schedule': { name: 'Royal Schedule', icon: '/assets/rs-symbol-color.svg' },
  'schoolsoft':     Integrations['SchoolSoft'],
  'sdui':           Integrations['Sdui'],
  'additio':        Integrations['Additio'],
  'pupil':          Integrations['Pupil'],
  'konecto':        Integrations['Konecto'],
}

type PartnersRecord = Record<Partner, { name?: string; icon?: string }>;
const partnersRecord: PartnersRecord = {
  'schoolsoft':     Integrations['SchoolSoft'],
  'sdui':           Integrations['Sdui'],
  'additio':        Integrations['Additio'],
  'pupil':          Integrations['Pupil'],
  'konecto':        Integrations['Konecto'],
}

const defaultOrgType: OrganizationType = 'school';
const defaultTheme:   Theme            = 'royal_schedule';

type School = {
  Kommunkod:      string;
  PeOrgNr:        string;
  Skolenhetskod:  string;
  Skolenhetsnamn: string;
  Status:         string;
}

type ProcessedSchool = School & {
  displayName: string;
}

type Form = {
  canCreateCompanies: FormControl<boolean>;
  id?:                FormControl<string>;
  name:               FormControl<string>;
  schoolCode?:        FormControl<string | null>;
  // organization?:      FormControl<string | null>;
  organizationType?:  FormControl<OrganizationType>;
  associatedPartner?: FormControl<Partner | null>;
  theme?:             FormControl<Theme>;
}

@Component({
    templateUrl: './add-edit.component.html',
    styleUrls: ['./add-edit.component.scss'],
    standalone: false
})
export class AddEditComponent implements OnDestroy {
  private readonly onDestroy = new Subject<void>();

  protected loading = false;
  protected readonly addMode: boolean;

  protected readonly possiblySwedishSchool: boolean = false;
  protected readonly urlRootDir: typeof apiConstants.ADMIN | typeof apiConstants.PARTNER;

  protected readonly organizationTypes: OrganizationType[];
  protected readonly partners:          Partial<PartnersRecord>;
  protected readonly themes:            Partial<ThemesRecord>;
  protected readonly organizations:     Organization[] = [];
  protected readonly form:              FormGroup<Form>;

  protected readonly onFilter        = new BehaviorSubject<{ Skolenhetsnamn: string } | { Skolenhetskod: string } | null>(null);
  protected readonly schools         = new BehaviorSubject<School[] | null>(null);
  protected readonly filteredSchools = new BehaviorSubject<ProcessedSchool[] | null>(null);

  constructor (
    @Inject(VIEW_ACCESS_TYPE)
    protected readonly viewAccessType: ViewAccessType,
    @Inject(MAT_DIALOG_DATA) { organization, urlRootDir }: Data,
    protected dialogRef:     MatDialogRef<AddEditComponent>,
    private   _env:          EnvironmentService,
    private   _http:         HttpService,
    private   _auth:         AuthService,
    private   _logger:       LoggerService,
    private   _notification: PushNotificationService,

  ) {
    this.possiblySwedishSchool = this.viewAccessType == 'admin' || this._auth.getAssociatedPartner() == 'schoolsoft';
    this.urlRootDir            = urlRootDir;

    // wether we are in add mode or edit mode
    this.addMode = ! organization;

    // setup organization types
    if (viewAccessType == 'admin') this.organizationTypes = structuredClone(organizationTypes as unknown as OrganizationType[]);
    else                 this.organizationTypes = ['school'];

    // setup partners
    if (viewAccessType == 'admin') this.partners = partnersRecord;
    else                 this.partners = Object.fromEntries(Util.functions.objectEntries(partnersRecord).filter(([x]) => x == this._auth.getAssociatedPartner()));

    // setup themes
    if (viewAccessType == 'admin') this.themes = themesRecord;
    else                 this.themes = Object.fromEntries(Util.functions.objectEntries(themesRecord).filter(([x]) => x == this._env.theme));


    // setup form
    this.form = new FormGroup<Form>({
                                  name:              new FormControl('',              { nonNullable: true, validators: Validators.required }),
      canCreateCompanies:  new FormControl(false, { nonNullable: true, validators: Validators.required }),
      // ...this.role == 'admin' ? { organization:      new FormControl(null)                                                                    } : { },
      ...this.viewAccessType == 'admin' ? { organizationType:  new FormControl(defaultOrgType,  { nonNullable: true, validators: Validators.required }) } : { },
      ...this.viewAccessType == 'admin' ? { associatedPartner: new FormControl(null,            { nonNullable: true                                  }) } : { },
      ...this.viewAccessType == 'admin' ? { theme:             new FormControl(this._env.theme, { nonNullable: true, validators: Validators.required }) } : { },
    });

    // if organization is provided, set the form values
    if (organization) {
      this.form.addControl('id', new FormControl(organization.id, { nonNullable: true, validators: Validators.required }), { emitEvent: false });
      this.form.patchValue({
        name: organization.name,
        // organization: organization.environment,
        organizationType:  organization.environment?.organizationType ?? defaultOrgType,
        associatedPartner: organization.associatedPartner,
        theme:             organization.environment?.theme            ?? defaultTheme,
      }, { emitEvent: false });

      // the school code is part of the identifiers and should not be modified from here
      // thus we hide it from the form
      this.form.removeControl('schoolCode', { emitEvent: false });
    }

    // update form depending on organization type
    this.form.valueChanges
    .pipe(
      takeUntil(this.onDestroy),
      map(({ organizationType }) => organizationType),
      startWith(this.form.controls.organizationType?.value),
    )
    .subscribe(orgType => {
      const addSchoolCode = orgType == 'school' && this.possiblySwedishSchool && this.addMode;
      if (addSchoolCode) this.form.addControl   ('schoolCode', new FormControl(null), { emitEvent: false });
      else               this.form.removeControl('schoolCode',                        { emitEvent: false });
    });

    // update the theme when the associated partner changes
    this.form.valueChanges
    .pipe(
      takeUntil(this.onDestroy),
      map(({ associatedPartner }) => associatedPartner),
      startWith(this.form.controls.associatedPartner?.value),
      distinctUntilChanged(),
      skip(1), // skip the first value, so that we do not automatically change the theme when the form is initialized
    )
    .subscribe(partner => {
      const theme = partner ?? defaultTheme;
      this.form.patchValue({ theme }, { emitEvent: false });
    });


    // setup autocomplete filter for schools
    if (this.possiblySwedishSchool) {
      // fetch all school names and codes from skolverket but via our backend
      this._http.get(`${ this.urlRootDir }/${ apiConstants.COMPANIES }/list`)
      .subscribe({
        next:  res => this.schools.next(sortBy(res, 'Skolenhetsnamn')),
        error: () => this._notification.pushError('tables.admin-tables.users.add.actions.fetch_from_skolverket.failure')
      });

      combineLatest({
        filter:  this.onFilter.pipe(filter(Boolean)),
        schools: this.schools .pipe(filter(Boolean)),
      })
      .pipe(
        takeUntil(this.onDestroy),
        debounceTime(100),
        map(({ filter, schools }) => {

          // only one can be active at a time
          const { key , val } = 'Skolenhetsnamn' in filter ?
            { key: 'Skolenhetsnamn' as const, val: escapeRegExp(filter.Skolenhetsnamn.toLowerCase()) } :
            { key: 'Skolenhetskod'  as const, val: escapeRegExp(filter.Skolenhetskod .toLowerCase()) };

          // filter
          return schools
          .reduce((acc, _school) => {
            const index = _school[key].toLowerCase().indexOf(val);
            if (index == -1) return acc;

            // clone the school object
            const school = structuredClone(_school) as ProcessedSchool;

            // compute the display name
            school.displayName =
              (['Skolenhetskod', 'Skolenhetsnamn'] as const)
              .map(_key => {
                // not the key we are filtering on
                if (_key != key) return school[_key];

                // find all occurrences of the filter value in the school value and wrap them in <b> tags
                const regex = new RegExp(val, 'gi');
                return school[_key].replace(regex, match => `<b>${match}</b>`);
              })
              .join(' / ');

            // add
            acc.push({ index, val: school });
            return acc;
          }, [] as { index: number, val: ProcessedSchool }[])
          .sort((a, b) => {
            // first sort by index
            if (a.index != b.index) return a.index - b.index;

            // then sort by the filter key
            return a.val[key].localeCompare(b.val[key]);
          })
          .map(({ val }) => val);
        })
      )
      .subscribe(this.filteredSchools);


      // listen to click outside a custom autocomplete or its parent form field to close the former
      fromEvent(document, 'click')
      .pipe(
        takeUntil(this.onDestroy),
        map((event: MouseEvent) => $(event.target!)),
      )
      .subscribe($target => {
        // find parent that is a form field
        const $formField = $target.closest('mat-form-field');

        // if such a parent does not exist, close custom autocomplete
        if ($formField.length === 0) {
          this.onFilter.next(null);
          return;
        }

        // look inside the parent form field for a custom autocomplete
        const $customAutocomplete = $formField.find('.custom-autocomplete');

        // if no custom autocomplete is found, close all custom autocomplete
        if ($customAutocomplete.length === 0) {
          this.onFilter.next(null);
          return;
        }
      });
    }
  }

  ngOnDestroy () {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  protected triggerAutocomplete (val: { Skolenhetsnamn: string } | { Skolenhetskod: string }) {
    // only trigger autocomplete if a swedish school
    if ( ! this.possiblySwedishSchool) return;

    // must also be a school type
    if (this.form.value.organizationType !== 'school') return;

    this.onFilter.next(val);
  }

  protected optionSelected (event: MatAutocompleteSelectedEvent) {
    const school = event.option.value as School;
    this.form?.patchValue({
      name:       school.Skolenhetsnamn,
      schoolCode: school.Skolenhetskod,
    });
  }

  protected submit () {
    this.loading = true;
    const val = this.form.value;

    // map from school code to identifiers
    const identifiers = [
      val.schoolCode ? {
        value:    val.schoolCode,
        country: 'SE',
        type:    'SchoolCode'
      }: null
    ].filter(Boolean);

    const environment = {
      theme:            val.theme,
      organizationType: val.organizationType,
      canCreateCompanies: val.canCreateCompanies,
    };

    const data = {
      name:              val.name,
      associatedPartner: val.associatedPartner,
      // organization:   val.organization,
      environment:       environment,
      ...this.addMode && identifiers.length && { identifiers },
    };

    this._http
    .post(`${ this.urlRootDir }/${ apiConstants.COMPANIES }${ val.id ? '/' + val.id : '' }`, { company: data })
    .subscribe({
      next: () => {
        this.loading = false;
        this.dialogRef.close(true);
      },
      error: err => {
        this.loading = false;
        this._logger.error(err);
        if (this.addMode) this._notification.pushError({ translate: 'tables.admin-tables.organizations.add-edit.actions.add.failure' });
        else              this._notification.pushError({ translate: 'common.edit_failed' });
      }
    });
  }
}
