import { inject, Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { apiConstants } from 'app/constants';
import { HttpService, LoggerService } from 'app/core';
import { ClusterStatus, RestoreStatus, Snapshot } from './types';


@Injectable({
  providedIn: 'root'
})
export class BackupsService {
  private readonly _http   = inject(HttpService);
  private readonly _logger = inject(LoggerService);

  getSnapshots () {
    return this._http.get<Snapshot[]>(`${ apiConstants.ADMIN }/backups/snapshots`)
    .pipe(
      catchError((x: unknown) => this.handleError(x))
    );
  }

  createCluster () {
    return this._http.post<void>(`${ apiConstants.ADMIN }/backups/cluster`, {})
    .pipe(
      catchError((x: unknown) => this.handleError(x))
    );
  }

  getClusterStatus () {
    return this._http.get<ClusterStatus>(`${ apiConstants.ADMIN }/backups/cluster`)
    .pipe(
      catchError((x: unknown) => this.handleError(x))
    );
  }

  deleteCluster () {
    return this._http.delete<void>(`${ apiConstants.ADMIN }/backups/cluster`)
    .pipe(
      catchError((x: unknown) => this.handleError(x))
    );
  }

  restoreSnapshot (snapshotId: string) {
    return this._http.post<RestoreStatus>(`${ apiConstants.ADMIN }/backups/restore`, { snapshotId })
    .pipe(
      catchError((x: unknown) => this.handleError(x))
    );
  }

  getRestoreStatus () {
    return this._http.get<RestoreStatus>(`${ apiConstants.ADMIN }/backups/restore`)
    .pipe(
      catchError((x: unknown) => this.handleError(x))
    );
  }

  // getData () {
  //   return this._http.get<any>(`${ apiConstants.ADMIN }/backups/get-data`)
  //   .pipe(
  //     catchError((x: unknown) => this.handleError(x))
  //   );
  // }

  downloadBackup (did: string) {
    return this._http.get<unknown>(`${ apiConstants.ADMIN }/backups/${ apiConstants.DIVISIONS }/${ did }/download`, undefined)
    .pipe(
      catchError((x: unknown) => this.handleError(x))
    );
  }

  private handleError (error: unknown) {
    this._logger.error(error);
    return throwError(() => error);
  }
}