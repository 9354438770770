import { NgModule                                          } from '@angular/core';
import { CommonModule                                      } from '@angular/common';
import { NgPipesModule                                     } from 'ngx-pipes';

import { AppCommonModule                                   } from 'app/common/common.module';
import { TranslationModule                                 } from 'app/core/translate/translate.module';
import { SharedPipesModule                                       } from 'app/shared/pipes/pipes.module';
import { SharedFormsModule      as AppFormsModule                } from 'app/shared/forms/forms.module';
import { SharedComponentsModule as AppComponentsModule           } from 'app/shared/components/components.module';
import { SharedDirectivesModule as AppDirectivesModule           } from '@app/shared/directives/directives.module';

import { ComponentsModule                                  } from '../components/components.module';
import { OrganizationsComponent                            } from './organizations.component';
import { ComponentsModule as OrganizationsComponentsModule } from './components/components.module';
import { LoadingComponent } from '@app/shared/components/loading/loading.component';
import { SearchComponent } from '@app/shared/components/search/search.component';
import { TypeSafeMatCellDefDirective } from '@app/shared/directives/type-safe-mat-cell-def/type-safe-mat-cell-def.directive';


@NgModule({
  declarations: [
    OrganizationsComponent
  ],
  exports: [
    OrganizationsComponent,
    OrganizationsComponentsModule
  ],
  imports: [
    CommonModule,
    NgPipesModule,
    AppCommonModule,
    TranslationModule,
    SharedPipesModule,
    AppFormsModule,
    AppComponentsModule,
    AppDirectivesModule,
    ComponentsModule,
    SearchComponent,
    LoadingComponent,
    TypeSafeMatCellDefDirective
  ]
})
export class OrganizationsModule { }
