import { NgModule                                } from '@angular/core';
import { CommonModule                            } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule                     } from '@angular/forms';
import { ScrollingModule                         } from '@angular/cdk/scrolling';

import { AppCommonModule                         } from 'app/common/common.module';
import { SharedPipesModule                             } from 'app/shared/pipes/pipes.module';
import { SharedComponentsModule as AppComponentsModule } from 'app/shared/components/components.module';
import { TranslationModule                       } from 'app/core/translate/translate.module';

import { AddEditComponent                        } from './add-edit/add-edit.component';
import { FormFieldErrorComponent } from '@app/shared/components/form-field-error/form-field-error.component';
import { LoadingComponent } from '@app/shared/components/loading/loading.component';

@NgModule({
  declarations: [
    AddEditComponent
  ],
  exports: [
    AddEditComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    SharedPipesModule,
    AppComponentsModule,
    TranslationModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    FormFieldErrorComponent,
    LoadingComponent
  ]
})
export class ComponentsModule { }
