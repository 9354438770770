<app-loading *ngIf="loading" borderRadius="4px"></app-loading>

<h1 mat-dialog-title>{{ 'tables.admin-tables.organizations.configure-identifiers.title' | translate }}</h1>
<mat-dialog-content>
  <section class="active">
    @for (identifier of organization.identifiers; track identifier.value) {
      <div class="identifier">
        <div class="country" [matTooltip]="countriesMap[identifier.country]">{{ identifier.country }}</div>
        <div class="content">
          <div class="royal-text-secondary">{{ types[identifier.type] | translate }}</div>
          <div class="royal-text-secondary">{{ identifier.value }}</div>
        </div>
        <button mat-icon-button (click)="pull(identifier)" [tabIndex]="-1" [matTooltip]="'common.remove' | translate">
          <mat-icon class="royal-icon material-symbols-outlined">close</mat-icon>
        </button>
      </div>
    }
  </section>

  <form [formGroup]="form">
    <div class="form-field-wrapper">
      <mat-icon class="form-field-icon material-symbols-outlined">language</mat-icon>
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>{{ 'common.country' | translate }}</mat-label>
        <mat-select formControlName="country" panelClass="dark-scrollbar">
          @for (country of countriesList; track country.code) {
            <mat-option [value]="country.code">
              {{ country.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-field-wrapper">
      <mat-icon class="form-field-icon">category</mat-icon>
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>{{ 'common.type' | translate }}</mat-label>
        <mat-select formControlName="type" panelClass="dark-scrollbar">
          @for (item of types | keyvalue; track item.key) {
            <mat-option [value]="item.key">
              {{ item.value | translate }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-field-wrapper">
      <mat-icon class="form-field-icon">fingerprint</mat-icon>
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>{{ 'common.value' | translate }}</mat-label>
        <input matInput type="text" formControlName="value">
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close type="button">
    {{ 'common.close' | translate }}
  </button>
  <button mat-button color="primary" type="submit" [disabled]=" ! form.valid" (click)="add()">
    {{ 'common.add' | translate }}
  </button>
</mat-dialog-actions>