import { Component, AfterViewInit, ViewContainerRef, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService, HttpService, PushNotificationService } from 'app/core';
import { apiConstants, Role } from 'app/constants';
import { Company as _Company } from 'app/shared/interfaces';
import { ActionButton } from 'app/shared/components/table-action-buttons/table-action-buttons.component';
import { TableCore } from '../table-core';
import { AddEditComponent, Data as AddEditComponentData } from './components/add-edit/add-edit.component';
import { ConfigureIdentifiersComponent, Data as ConfigureIdentifiersComponentData } from './components/configure-identifiers/configure-identifiers.component';
import { ConfigureComputeEnvironmentComponent, Data as ConfigureComputeEnvironmentComponentData } from './components/configure-compute-environment/configure-compute-environment.component';
import { IntegrationsComponent, Data as IntegrationsComponentData } from './components/integrations/integrations.component';
import { AppFeaturesComponent, Data as AppFeaturesComponentData } from './components/app-features/app-features.component';
import { AuthData } from '@app/core/auth/auth.interface';
import { DataSourceService } from '@app/shared/services/data-source/data-source.service';
import { DialogsService } from '@app/shared/services/dialogs/dialogs.service';


type Organization = _Company.complete & {
  computeEnvironment?: { version: string };
  organization?: { name: string };
  divisions?: unknown[];
};

@Component({
    selector: 'app-organizations-table',
    templateUrl: './organizations.component.html',
    styleUrls: ['./organizations.component.scss'],
    providers: [
        DataSourceService
    ],
    standalone: false
})
export class OrganizationsComponent extends TableCore<Organization> implements AfterViewInit {
  private readonly _dialog           = inject(DialogsService);
  private readonly _auth             = inject(AuthService);
  private readonly _http             = inject(HttpService);
  private readonly _notification     = inject(PushNotificationService);
  private readonly _router           = inject(Router);
  private readonly _matDialog        = inject(MatDialog);
  private readonly _viewContainerRef = inject(ViewContainerRef);

  protected readonly actionButtons: ActionButton<Organization>[] = [
    {
      icon:    'login',
      label:   { translate: 'tables.admin-tables.organizations.actions.login' },
      action:  (x) => { this.login(x, 'unrestricted') }
    },
    {
      icon:    'supervisor_account',
      label:   { translate: 'tables.admin-tables.organizations.actions.login-administrator' },
      action:  (x) => { this.login(x, 'administrator') }
    },
    this.viewAccessType == 'admin' ? {
      icon:    'vpn_key',
      label:   { translate: 'tables.admin-tables.organizations.actions.generate_access_key' },
      action:  (x) => { this.generateAccessKey(x) }
    } : undefined,
    this.viewAccessType == 'admin' ? {
      icon:    'developer_board',
      label:   { translate: 'tables.admin-tables.organizations.actions.configure_environment' },
      action:  (x) => { this.configureEnvironment(x) }
    } : undefined,
    {
      icon:    'widgets',
      label:   { translate: 'tables.admin-tables.organizations.actions.app_features' },
      action:  (x) => { this.openAppFeaturesDialog(x) }
    },
    {
      icon:    'fingerprint',
      label:   { translate: 'tables.admin-tables.organizations.actions.configure_identifiers' },
      action:  (x) => { this.configureIdentifiers(x) },
    },
    {
      icon:    { icon: 'settings' },
      label:   { translate: 'tables.admin-tables.organizations.actions.edit' },
      action:  (x) => { this.openAddEditDialog(x) }
    },
    {
      icon:    'delete_outline',
      label:   { translate: 'tables.admin-tables.organizations.actions.delete' },
      action:  (x) => { this.deleteOne(x) }
    }
  ];

  constructor () {
    super();

    this.setColumns([
      'name',
      // 'organization',
      this.viewAccessType == 'admin' ? 'organizationType' : undefined,
      this.viewAccessType == 'admin' ? 'associatedPartner' : undefined,
      this.viewAccessType == 'admin' ? 'theme' : undefined,
      'divisions',
      'identifiers',
      this.viewAccessType == 'admin' ? 'version' : undefined,
      // this.role == 'admin' ? 'environment' : undefined,
      'createdAt',
      'actions'
    ]);

  }

  ngAfterViewInit() {
    // cannot fetch data in constructor because of @ViewChild
    this.dataSource.init({
      source: `${ this.urlRootDir }/${ apiConstants.COMPANIES }`,
      transformRemoteSourceData: docs => {
        return docs.map(x => {
          // must be an object
          if ( ! x || typeof x !== 'object') return null;

          // ensure that the environment is set
          if ( ! ('environment' in x && x.environment && typeof x.environment === 'object')) {
            (x as Organization).environment = { };
          }

          return x as Organization;
        })
        .filter(Boolean)
      },
      matPaginator:    this.paginatorComponent,
      matSort:         this.sortComponent,
      searchComponent: this.searchComponent,
      filterComponent: this.filterComponent,
    });
  }

  protected deleteOne (org: Organization) {
    this._dialog.openRemoveDialog()
    .subscribe((result) => {
      if ( ! result) return;

      this._http.delete(`${ this.urlRootDir }/${ apiConstants.COMPANIES }/${ org.id }`, undefined)
      .subscribe({
        next:  () => this.dataSource.update(),
        error: (err) => {
          this.logger.error(err);
          this._notification.pushError({ translate: 'tables.admin-tables.organizations.actions.delete.failure' });
        }
      });
    });
  }

  protected openAppFeaturesDialog (org: Organization) {
    this._matDialog.open<AppFeaturesComponent, AppFeaturesComponentData>(AppFeaturesComponent, {
      viewContainerRef: this._viewContainerRef,
      panelClass: ['position-relative'],
      data: {
        organization: org
      }
    })
    .afterClosed()
    .subscribe(() => this.dataSource.update());
  }

  protected openAddEditDialog (org?: Organization) {
    this._matDialog.open<AddEditComponent, AddEditComponentData>(AddEditComponent, {
      viewContainerRef: this._viewContainerRef,
      data: {
        urlRootDir:   this.urlRootDir,
        organization: org
      }
    })
    .afterClosed()
    .subscribe(res => {
      if (res) void this.dataSource.update()
    });
  }


  protected login (org: Organization, role: Role): void {
    this._http.get(`${ this.urlRootDir }/authenticate/company/${ org.id }`, { role })
    .subscribe({
      next: (res: AuthData) => {
        this._auth.login(res);

        // go main page
        void this._router.navigateByUrl('/');
      },
      error: err => {
        this.logger.error(err);
        this._notification.pushError({ translate: 'tables.admin-tables.organizations.actions.login.failure' });
      }
    });
  }

  public generateAccessKey (org: Organization): void {
    this._matDialog.open<IntegrationsComponent, IntegrationsComponentData>(IntegrationsComponent, {
      viewContainerRef: this._viewContainerRef,
      panelClass: ['position-relative'],
      data: {
        organization: org
      }
    });
  }

  public configureEnvironment (org: Organization): void {
    this._matDialog.open<ConfigureComputeEnvironmentComponent, ConfigureComputeEnvironmentComponentData>(ConfigureComputeEnvironmentComponent, {
      viewContainerRef: this._viewContainerRef,
      panelClass: ['position-relative'],
      data: {
        organization: org
      }
    })
    .afterClosed()
    .subscribe(res => {
      if (res) void this.dataSource.update()
    });
  }

  public configureIdentifiers (org: Organization): void {
    this._matDialog.open<ConfigureIdentifiersComponent, ConfigureIdentifiersComponentData>(ConfigureIdentifiersComponent, {
      viewContainerRef: this._viewContainerRef,
      panelClass: ['position-relative'],
      data: {
        organization: org,
        urlRootDir:   this.urlRootDir
      }
    })
    .afterClosed()
    .subscribe(res => {
      if (res) void this.dataSource.update()
    });
  }

}