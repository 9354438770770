import { Component,
         Input,
         OnDestroy                       } from '@angular/core';
import { UntypedFormGroup,
         UntypedFormBuilder              } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS,
         MomentDateAdapter               } from '@angular/material-moment-adapter';
import { DateAdapter,
         MAT_DATE_FORMATS,
         MAT_DATE_LOCALE                 } from '@angular/material/core';
import { Subject,
         Observable                      } from 'rxjs';
import { takeUntil                       } from 'rxjs/operators';

import { DataSourceFilter                } from '@app/shared/services/data-source/types';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
    providers: [
        // these two providers makes the datepicker return utc moments
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: true }
        }, {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        // date picker display options
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'YYYY-MM-DD',
                },
                display: {
                    dateInput: 'YYYY-MM-DD',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            },
        },
    ],
    standalone: false
})
export class FilterComponent implements OnDestroy, DataSourceFilter {
  private onDestroy = new Subject<void>();

  public formGroup:  UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {
    this.formGroup = this._fb.group({
      activation: [null, []],
      start:      [null, []],
      end:        [null, []],
    });
  }

  ngOnDestroy () {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public change(): Observable<string> {
    return this.formGroup.valueChanges
        .pipe(
          takeUntil(this.onDestroy)
        )
  }

  @Input()
  get value(): string {
    return this._value;
  }
  private _value: any;

  @Input()
  get activeFilters(): number | undefined {
    return this._activeFilters || undefined;
  }
  private _activeFilters: number;


  _handleInput(): void {
    const { value } = this.formGroup;
    const _activeFilters: any[] = Object.entries(value).filter(([key, val]: any) => val != null);
    this._activeFilters = _activeFilters.length;
    this._value = Object.fromEntries(_activeFilters);
  }
}
