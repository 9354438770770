import { Component, AfterViewInit, ViewContainerRef, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { filter, map, of, switchMap } from 'rxjs';
import { environment } from '@env/environment';
import { AuthService, HttpService, PushNotificationService } from 'app/core';
import { MatDialog } from 'app/common';
import { apiConstants } from 'app/constants';
import { User as _User } from 'app/shared/interfaces';
import { ActionButton } from 'app/shared/components/table-action-buttons/table-action-buttons.component';
import { TableCore } from '../table-core';
import { AddEditComponent, Data as AddEditComponentData } from './components/add-edit/add-edit.component';
import { DataSourceService } from '@app/shared/services/data-source/data-source.service';
import { DialogsService } from '@app/shared/services/dialogs/dialogs.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type User = _User.populated & {
  online?: boolean;
  lastLogin?: string;
}

@Component({
    selector: 'app-users-table',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    providers: [
        DataSourceService
    ],
    standalone: false
})
export class UsersComponent extends TableCore<User> implements AfterViewInit {
  private readonly _dialog           = inject(DialogsService);
  private readonly _auth             = inject(AuthService);
  private readonly _http             = inject(HttpService);
  private readonly _notification     = inject(PushNotificationService);
  private readonly _router           = inject(Router);
  private readonly _matDialog        = inject(MatDialog);
  private readonly _viewContainerRef = inject(ViewContainerRef);
  private readonly _clipboard        = inject(Clipboard);

  protected readonly actionButtons: ActionButton<User>[] = [
    {
      icon:     'login',
      label:    { translate: 'tables.admin-tables.users.actions.login' },
      action:   (x) => { this.login(x) },
      disabled: (x) => x.role == this.viewAccessType
    },
    // {
    //   icon:   'timeline',
    //   label:  'Activity',
    //   action: (x) => { console.log(x); }
    // },
    {
      icon:   'mail_outline',
      label:  { translate: 'tables.admin-tables.users.actions.mail' },
      action: (x) => { this.mail(x); }
    },
    {
      icon:   { icon: 'settings' },
      label:  { translate: 'tables.admin-tables.users.actions.edit' },
      action: (x) => { this.openAddEditDialog(x) }
    },
    {
      icon:     'delete_outline',
      label:    { translate: 'tables.admin-tables.users.actions.delete' },
      action:   (x) => { this.deleteOne(x); },
      disabled: (x) => this._auth.getUserId() == x.id
    }
  ];

  constructor () {
    super();

    this.setColumns([
      'username',
      'company',
      'role',
      this.viewAccessType == 'admin' ? 'associatedPartner' : undefined,
      'lastLogin',
      'createdAt',
      'activation',
      'actions'
    ]);

  }

  ngAfterViewInit() {
    // cannot fetch data in constructor because of @ViewChild
    this.dataSource.init({
      source:          `${ this.urlRootDir }/${ apiConstants.USERS }`,
      matPaginator:    this.paginatorComponent,
      matSort:         this.sortComponent,
      searchComponent: this.searchComponent,
      filterComponent: this.filterComponent,
    });
  }

  protected copyVerificationLink (user: User) {
    this._clipboard.copy(environment.APP_BASE_URL + 'activation/' + user.verifyToken);
  }

  protected deleteOne (user: User) {
    this._dialog.openRemoveDialog()
    .subscribe((result) => {
      if ( ! result) return;

      this._http.delete(`${ this.urlRootDir }/${ apiConstants.USERS }/${ user.id }`, undefined)
      .subscribe({
        next:  () => this.dataSource.update(),
        error: (err) => {
          this.logger.error(err);
          this._notification.pushError({ translate: 'tables.admin-tables.users.actions.delete.failure' });
        }
      });
    });
  }

  protected openAddEditDialog (user?: User) {
    this._matDialog.open<AddEditComponent, AddEditComponentData>(AddEditComponent, {
      viewContainerRef: this._viewContainerRef,
      panelClass: ['position-relative'],
      data: {
        user:       user,
        urlRootDir: this.urlRootDir
      }
    })
    .afterClosed()
    .pipe(filter(Boolean))
    .subscribe(() => this.dataSource.update());
  }

  protected mail (user: User) {
    this._dialog.openConfirmActionDialog()
    .subscribe(result => {
      if ( ! result) return;

      this._http.post(`${ this.urlRootDir }/mail`, { to: user.id }, undefined)
      .subscribe({
        next:  () => this._notification.pushConfirmation({ translate: 'tables.admin-tables.users.actions.mail.success' }),
        error: () => this._notification.pushError({ translate: 'tables.admin-tables.users.actions.mail.failure' })
      });
    });
  }

  protected login (user: User): void {
    // if user has the same role as the current user, ignore
    if (user.role == this.viewAccessType) {
      console.warn('Cannot login to user with same role');
      this._notification.pushError({ translate: 'tables.admin-tables.users.actions.login.failure' });
      return;
    }

    of(null)
    .pipe(
      map(() => {
        if (this.viewAccessType == 'admin' && user.role == 'partner') {
          return `authenticate/partner/${ user.associatedPartner }`;
        }
        return `authenticate/company/${ user.company?.id }`;
      }),
      switchMap(url => this._http.get(`${ this.urlRootDir }/${url}`)),
      takeUntilDestroyed(this.destroyRef)
    )
    .subscribe({
      next: res => {
        this._auth.login(res);

        // go main page
        void this._router.navigateByUrl('/');
      },
      error: err => {
        this.logger.error(err);
        this._notification.pushError({ translate: 'tables.admin-tables.users.actions.login.failure' });
      }
    });
  }

}