import { Inject,
         Component                       } from '@angular/core';
import { Clipboard                       } from '@angular/cdk/clipboard';
import { FormControl,
         FormGroup,
         Validators                      } from '@angular/forms';
import { firstValueFrom                  } from 'rxjs';

import { HttpService,
         PushNotificationService         } from 'app/core';
import { Company                         } from 'app/shared/interfaces';
import { MAT_DIALOG_DATA,
         MatDialogRef                    } from 'app/common';
import { apiConstants                    } from '@app/constants';
import { Integrations                    } from '@app/core/environment/environment.constants';
import { IntegrationTypes                } from '@app/core/environment/environment.types';

export type Data = {
  organization: Company.complete;
}

type Form = {
  origin: FormControl<IntegrationTypes | null>;
}

@Component({
    selector: 'app-integrations',
    templateUrl: './integrations.component.html',
    styleUrls: ['./integrations.component.scss'],
    standalone: false
})
export class IntegrationsComponent {
  protected loading = false;

  private organization: Company.complete;

  protected integrations: Record<string, { name?: string, icon?: string, value?: IntegrationTypes }> = Integrations;

  protected form: FormGroup<Form>;

  constructor (
    @Inject(MAT_DIALOG_DATA) { organization }: Data,
    private _dialogRef:     MatDialogRef<IntegrationsComponent>,
    private _http:          HttpService,
    private _clipBoard:     Clipboard,
    private _notifications: PushNotificationService
  ) {
    this.organization = organization;

    this.form = new FormGroup<Form>({
      origin: new FormControl(null, { nonNullable: true, validators: Validators.required })
    });
  }

  protected async submit(): Promise<void> {
    this.loading = true;

    try {
      const token = await firstValueFrom(this._http.post(`${ apiConstants.ADMIN }/integrations/generate`, { company: this.organization.id, ...this.form.value }));
      this._clipBoard.copy(token);
      this._dialogRef.close(true);
    } catch (error) {
      console.warn(error);
      this._notifications.pushError();
    }

    this.loading = false;
  }
}
