import { Component, AfterViewInit, inject, input, computed } from '@angular/core';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver-es';
import { AuthService, HttpService, PushNotificationService } from 'app/core';
import { apiConstants } from 'app/constants';
import { Company, Division as _Division } from 'app/shared/interfaces';
import { ActionButton } from 'app/shared/components/table-action-buttons/table-action-buttons.component';
import { TableCore } from '../table-core';
import { DataSourceService } from '@app/shared/services/data-source/data-source.service';
import { DialogsService } from '@app/shared/services/dialogs/dialogs.service';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { BackupsService } from '@app/view/admin/backups/backups.service';


type Division = _Division.populated & {
  company: Pick<Company.complete, 'id' | 'name'>;
  editedAt?: string;
};

@Component({
    selector: 'app-divisions-table',
    templateUrl: './divisions.component.html',
    styleUrls: ['./divisions.component.scss'],
    providers: [
        DataSourceService
    ],
    standalone: false
})
export class DivisionsComponent extends TableCore<Division> implements AfterViewInit {
  private readonly _dialog       = inject(DialogsService);
  private readonly _auth         = inject(AuthService);
  private readonly _http         = inject(HttpService);
  private readonly _notification = inject(PushNotificationService);
  private readonly _router       = inject(Router);
  private readonly _backups      = inject(BackupsService);

  public readonly showDownloadButton = input(false, { transform: coerceBooleanProperty });
  protected readonly actionButtons = computed((): ActionButton<Division>[] => {
    if (this.showDownloadButton()) {
      return [
        {
          icon:   'cloud_download',
          label:  'Download schedule backup',
          action: (x: Division) => { this.downloadBackup(x) }
        },
      ];
    } else {
      return [
        {
          icon:   'login',
          label:  { translate: 'tables.admin-tables.divisions.actions.login' },
          action: (x: Division) => { this.login(x) }
        },
        this.viewAccessType === 'admin' ? {
          icon:   'cloud_download',
          label:  { translate: 'tables.admin-tables.divisions.actions.download' },
          action: (x: Division) => { this.download(x) }
        } : undefined,
        {
          icon:   'delete_outline',
          label:  { translate: 'tables.admin-tables.divisions.actions.delete' },
          action: (x: Division) => { this.deleteOne(x) }
        }
      ].filter(button => button !== undefined) as ActionButton<Division>[];
    }
  });

  constructor () {
    super();

    this.setColumns([
      'displayName',
      // 'active',
      'id',
      'organization',
      this.viewAccessType == 'admin' ? 'associatedPartner' : undefined,
      'editedAt',
      'createdAt',
      'actions'
    ]);

  }


  ngAfterViewInit() {
    // cannot fetch data in constructor because of @ViewChild
    this.dataSource.init({
      source:          `${ this.urlRootDir }/${ apiConstants.DIVISIONS }`,
      matPaginator:    this.paginatorComponent,
      matSort:         this.sortComponent,
      searchComponent: this.searchComponent,
      filterComponent: this.filterComponent,
    });
  }

  protected deleteOne (div: Division) {
    this._dialog.openRemoveDialog()
    .subscribe((result) => {
      if ( ! result) return;

      this._http.delete(`${ this.urlRootDir }/${ apiConstants.DIVISIONS }/${ div.id }`, undefined)
      .subscribe({
        next:  () => this.dataSource.update(),
        error: (err) => {
          this.logger.error(err);
          this._notification.pushError({ translate: 'tables.admin-tables.divisions.actions.delete.failure' });
        }
      });
    });
  }


  protected login (div: Division) {
    this._http.get(`${ this.urlRootDir }/authenticate/company/${ div.company.id }`)
    .subscribe({
      next: res => {
        this._auth.login(res);

        // go to schedule
        void this._router.navigate(['schedule', div.id, 'data']);
      },
      error: err => {
        this.logger.error(err);
        this._notification.pushError({ translate: 'tables.admin-tables.divisions.actions.login.failure' });
      }
    });
  }


  protected download (div: Division) {
    this._http.get(`${ this.urlRootDir }/${ apiConstants.DIVISIONS }/${ div.id }/file`, undefined)
    .subscribe({
      next: res => {
        const blob = new Blob([JSON.stringify(res, null, 2)], { type: 'application/json' });
        saveAs(blob,`input.json`);
      },
      error: () => this._notification.pushError()
    });
  }


  protected downloadBackup (division: Division) {
    this._backups.downloadBackup(division.id)
    .subscribe({
      next: res => {
        const blob = new Blob([JSON.stringify(res, null, 2)], { type: 'application/json' });
        saveAs(blob, `backup_${ division.displayName ?? 'unknown' }.json`);
      },
      error: (e) => {
        this._notification.pushError(e);
      }
    });
  }

}