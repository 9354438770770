import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { HttpService } from '@app/core';
import { apiConstants } from '@app/constants';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AppCommonModule } from '@app/common/common.module';
import { TranslationModule } from '@app/core/translate/translate.module';
import { MAT_DIALOG_DATA, MatDialogRef, Util } from 'app/common';
import { startWith } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Types } from '@app/shared/interfaces';

export type Data = {
  organization: Types.companies;
};

@Component({
  templateUrl: './app-features.component.html',
  styleUrls:   ['./app-features.component.scss'],
  imports:     [
    CommonModule,
    AppCommonModule,
    ReactiveFormsModule,
    TranslationModule
  ]
})
export class AppFeaturesComponent {
  private readonly _http      = inject(HttpService);
  private readonly _dialogRef = inject<MatDialogRef<AppFeaturesComponent>>(MatDialogRef);
  private readonly _data      = inject<Data>(MAT_DIALOG_DATA);

  private readonly _appFeatures = this._data.organization?.environment?.appFeatures;

  protected readonly form = new FormGroup({
    substitutionPlanner: new FormGroup({
      enabled: new FormControl(this._appFeatures?.substitutionPlanner?.enabled ?? false, { nonNullable: true })
    }),

    statisticsModule: new FormGroup({
      enabled:         new FormControl(this._appFeatures?.statisticsModule?.enabled         ?? false, { nonNullable: true }),
      scb:             new FormControl(this._appFeatures?.statisticsModule?.scb             ?? false, { nonNullable: true }),
      lunchCongestion: new FormControl(this._appFeatures?.statisticsModule?.lunchCongestion ?? false, { nonNullable: true }),
    })
  });

  constructor () {
    // individual statistics module controls should react to the enabled state of the statistics module
    const statisticsModuleEnabled = this.form.controls.statisticsModule.controls.enabled;
    statisticsModuleEnabled.valueChanges
      .pipe(
        startWith(statisticsModuleEnabled.value),
        takeUntilDestroyed()
      )
      .subscribe(x => {
        Util.functions.objectEntries(this.form.controls.statisticsModule.controls)
          .forEach(([ key, ctrl ]) => {
            if (key === 'enabled') return;
            ctrl[x ? 'disable' : 'enable']();
            ctrl.setValue(x || ctrl.value);
          });
      });
  }

  protected submit () {
    const value = this.form.value;
    this._http.post(`${ apiConstants.ADMIN }/companies/${ this._data.organization.id }/environments/app_features`, value)
      .subscribe(() => {
        this._dialogRef.close();
      });
  }
}