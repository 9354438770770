import { NgModule                        } from '@angular/core';
import { CommonModule                    } from '@angular/common';
import { FormsModule,
         ReactiveFormsModule             } from '@angular/forms';

import { AppCommonModule                 } from 'app/common/common.module';
import { TranslationModule               } from 'app/core/translate/translate.module';
import { FilterComponent                 } from './filter.component';


@NgModule({
  declarations: [
    FilterComponent
  ],
  exports: [
    FilterComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslationModule
  ]
})
export class FilterModule { }
