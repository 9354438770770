import { Component,
         Inject,
         OnDestroy                        } from '@angular/core';
import { FormControl,
         FormGroup,
         ValidationErrors,
         Validators                       } from '@angular/forms';
import { BehaviorSubject,
         Subject,
         startWith,
         takeUntil                        } from 'rxjs';

import { MAT_DIALOG_DATA,
         MatDialogRef,
         Util                             } from 'app/common';
import { AuthService,
         HttpService,
         LoggerService,
         PushNotificationService          } from 'app/core';
import { Integrations                     } from 'app/core/environment/environment.constants';
import { Partner,
         VIEW_ACCESS_TYPE,
         ViewAccessType,
         Role as _Role,
         apiConstants                     } from 'app/constants';
import { Company,
         User                             } from 'app/shared/interfaces';
import { sortBy } from 'lodash';



type Role = _Role; // Extract<_Role, 'admin' | 'partner' | 'unrestricted'>;
const defaultRole: Role = 'unrestricted';

const roles: Role[] = ['admin', 'partner', 'unrestricted'];

type PartnersRecord = Record<Partner, { name?: string; icon?: string }>;
const partnersRecord: PartnersRecord = {
  'schoolsoft':     Integrations['SchoolSoft'],
  'sdui':           Integrations['Sdui'],
  'additio':        Integrations['Additio'],
  'pupil':          Integrations['Pupil'],
  'konecto':        Integrations['Konecto'],
}

type Organization = Pick<Company.complete, 'id' | 'name'>;

type AccessForm = {
  role:               FormControl<Role>;
  organization?:      FormControl<string | null>;
  associatedPartner?: FormControl<Partner | null>;
};

type Form = {
  id?:      FormControl<string>;
  username: FormControl<string>;
  access:   FormGroup<AccessForm>;
}

export type Data = {
  user?:      User.populated;
  urlRootDir: typeof apiConstants.ADMIN | typeof apiConstants.PARTNER;
}

@Component({
    templateUrl: './add-edit.component.html',
    styleUrls: ['./add-edit.component.scss'],
    standalone: false
})
export class AddEditComponent implements OnDestroy {
  private readonly onDestroy = new Subject<void>();

  protected loading = false;
  protected readonly addMode: boolean;

  protected readonly urlRootDir: typeof apiConstants.ADMIN | typeof apiConstants.PARTNER;

  protected readonly roles:    Role[];
  protected readonly partners: Partial<PartnersRecord>;
  protected readonly form:     FormGroup<Form>;

  protected readonly organizations = new BehaviorSubject<Organization[] | null>(null);

  constructor (
    @Inject(VIEW_ACCESS_TYPE)
    protected readonly viewAccessType: ViewAccessType,
    @Inject(MAT_DIALOG_DATA) { user, urlRootDir }: Data,
    protected  dialogRef:    MatDialogRef<AddEditComponent>,
    private    _http:        HttpService,
    private    _auth:        AuthService,
    private   _logger:       LoggerService,
    private   _notification: PushNotificationService,
  ) {
    this.urlRootDir = urlRootDir;

    // wether we are in add mode or edit mode
    this.addMode = ! user;

    // setup roles
    if (viewAccessType == 'admin') this.roles = roles;
    else                 this.roles = roles.filter(x => x != 'admin');

    // setup partners
    if (viewAccessType == 'admin') this.partners = partnersRecord;
    else                 this.partners = Object.fromEntries(Util.functions.objectEntries(partnersRecord).filter(([x]) => x == this._auth.getAssociatedPartner()));

    // setup form
    this.form = new FormGroup<Form>({
      username: new FormControl<string>('', {
        nonNullable: true,
        validators: (x: FormControl<string>) => this.emailValidator(x),
      }),
      access: new FormGroup<AccessForm>({
        role: new FormControl<Role>(defaultRole, { nonNullable: true, validators: Validators.required }),
      }, {
        validators: [
          (x: FormGroup<AccessForm>) => this.companyValidator(x),
          (x: FormGroup<AccessForm>) => this.associatedPartnerValidator(x),
        ]
      })
    });

    // update form depending on role
    this.form.controls.access.controls.role.valueChanges
    .pipe(
      takeUntil(this.onDestroy),
      startWith(this.form.controls.access.controls.role.value),
    )
    .subscribe((selectedRole) => {
      if (selectedRole == 'admin') {
        delete this.form.controls.access.controls.organization;
        delete this.form.controls.access.controls.associatedPartner;
      }
      else if (selectedRole == 'partner') {
        if (viewAccessType == 'admin') this.form.controls.access.addControl('associatedPartner', new FormControl(null, { validators: Validators.required }));
        delete this.form.controls.access.controls.organization;
      }
      else if (selectedRole == 'unrestricted') {
        this.form.controls.access.addControl('organization', new FormControl(null, { validators: Validators.required }));
        delete this.form.controls.access.controls.associatedPartner;

        // download a list of all companies
        this.fetchCompanies();
      }
      else {
        this._logger.error(new Error(`Unknown role: ${ selectedRole }`));
      }
    });


    // if organization is provided, set the form values
    if (user) {
      this.form.addControl('id', new FormControl(user.id, { nonNullable: true, validators: Validators.required }), { emitEvent: false });
      this.form.patchValue({
        username: user.username,
      }, { emitEvent: false });

      // update role and await for the form to be updated before supplying the other values
      // (hence emit value = true)
      this.form.controls.access.controls.role.setValue(user.role);

      this.form.controls.access.patchValue({
        organization:      user.company?.id,
        associatedPartner: user.associatedPartner
      }, { emitEvent: false });

      // do not allow to edit the role
      this.form.controls.access.controls.role.disable({ emitEvent: false });
    }
  }

  ngOnDestroy () {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private fetchCompanies () {
    this._http.get(`${ this.urlRootDir }/${ apiConstants.COMPANIES }`, { limit: null })
    .subscribe({
      next:  (res) => {
        this.organizations.next(sortBy(res.docs, 'name'));
      },
      error: (err) => {
        this._logger.error(err);
        this._notification.pushError({ translate: 'tables.admin-tables.users.add-edit.actions.fetch_companies.failure' });
      }
    });
  }

  private associatedPartnerValidator (fg: FormGroup<AccessForm>): ValidationErrors {
    // only relevant to admins
    if (this.viewAccessType !== 'admin') return { };

    const role = fg.controls.role.value;   // since it might be disabled
    const { associatedPartner } = fg.value;
    // an associated partner must be selected if role is partner
    if (role === 'partner' && ! associatedPartner) return { 'partner': true };
    return { };
  }

  private companyValidator (fg: FormGroup<AccessForm>): ValidationErrors {
    const role = fg.controls.role.value;   // since it might be disabled
    const { organization } = fg.value;
    // a company must be selected if role is not admin or partner
    if (role !== 'admin' && role !== 'partner' && ! organization) return { 'organization': true };
    return { };
  }

  private emailValidator (fc: FormControl<string>): ValidationErrors {
    const { value } = fc;
    // email must be valid
    if ( ! value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) return { 'email': true };
    return { };
  }

  protected submit () {
    this.loading = true;
    const val = this.form.value;

    const data = {
      username:          val.username,
      role:              val.access?.role,
      associatedPartner: val.access?.associatedPartner,
      company:           val.access?.organization
    };

    this._http
    .post(`${ this.urlRootDir }/${ apiConstants.USERS }${ val.id ? '/' + val.id : '' }`, data)
    .subscribe({
      next: () => {
        this.loading = false;
        this.dialogRef.close(true);
      },
      error: err => {
        this.loading = false;
        this._logger.error(err);
        if (this.addMode) this._notification.pushError({ translate: 'tables.admin-tables.users.add-edit.actions.add.failure' });
        else              this._notification.pushError({ translate: 'common.edit_failed' });
      }
    });
  }
}
