import { Directive, ViewChild, inject, DestroyRef, input, signal } from '@angular/core';
import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatPaginator } from 'app/common';
import { map, share, timer } from 'rxjs';
import { MatSort } from 'app/common';
import { apiConstants, VIEW_ACCESS_TYPE } from 'app/constants';
import { LoggerService } from 'app/core';
import { FilterComponent } from './components/filter/filter.component';
import { SearchComponent } from '@app/shared/components/search/search.component';
import { DataSourceService } from '@app/shared/services/data-source/data-source.service';
import { uniq } from 'lodash';
import { environment } from '@env/environment';


function setUrlRootDir (viewAccessType: string, logger: LoggerService) {
  if      (viewAccessType === 'admin')   return apiConstants.ADMIN;
  else if (viewAccessType === 'partner') return apiConstants.PARTNER;
  else {
    logger.error(new Error('Invalid view access type, expected "admin" or "partner". defaulting to "partner"'));
    return 'partner';
  }
}


@Directive({
    host: {
        class: 'app-table-component'
    },
    standalone: false
})
export abstract class TableCore<T extends { id: string }> {
  protected readonly destroyRef     = inject(DestroyRef);
  protected readonly viewAccessType = inject(VIEW_ACCESS_TYPE);
  protected readonly dataSource     = inject<DataSourceService<T>>(DataSourceService);
  protected readonly logger         = inject(LoggerService);

  // inputs
  public readonly showFilter    = input(false, { alias: 'filter', transform: x => coerceBooleanProperty(x) });
  public readonly showPaginator = input(true, { alias: 'paginator', transform: x => coerceBooleanProperty(x) });
  public readonly pageSizes     = input<number[]>([10, 30, 50]);
  public readonly pageSize      = input(10, { transform: x => coerceNumberProperty(x) });

  protected readonly now$ = timer(0, 1000).pipe(takeUntilDestroyed(), map(() => new Date()), share());

  // components: REWRITE TO USE "viewChild"
  @ViewChild(SearchComponent, { static: false })  searchComponent?: SearchComponent;
  @ViewChild(MatPaginator)     paginatorComponent?: MatPaginator;
  @ViewChild(MatSort)          sortComponent?:      MatSort;
  @ViewChild(FilterComponent)  filterComponent?:    FilterComponent;

  protected readonly appBaseUrl = environment.APP_BASE_URL;
  protected readonly urlRootDir = setUrlRootDir(this.viewAccessType, this.logger);

  protected setColumns (val: (string | undefined)[]) { this._columns.set(uniq(val.filter(Boolean))) }
  private readonly _columns = signal<string[]>([]);
  protected readonly columns = this._columns.asReadonly();

}