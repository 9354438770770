<mat-toolbar class="app-table-toolbar">

  <app-search class="search" placeholder="common.search.organizations"></app-search>

  <div class="spacer"></div>

  <button mat-mini-fab
    type="button"
    color="primary"
    matTooltip="{{ 'tables.admin-tables.organizations.actions.add' | translate }}"
    (click)="openAddEditDialog()"
  >
    <mat-icon>add</mat-icon>
  </button>

</mat-toolbar>

<div class="app-table-container">

  <app-loading [loading]="dataSource.loading$ | async"></app-loading>
  <app-fetch-fail-pane [show]="dataSource.error$ | async"></app-fetch-fail-pane>

  <table #table class="header-nowrap"
    mat-table [dataSource]="dataSource"
    matSort matSortActive="createdAt" matSortDirection="desc"
  >

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'tables.admin-tables.organizations.columns.name' | translate }}</th>
      <td mat-cell *matCellDef="let element; table: table">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="version">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'tables.admin-tables.organizations.columns.version' | translate }}</th>
      <td mat-cell *matCellDef="let element; table: table">
        <span [class.highlight]="element.computeEnvironment?.version">{{ element.computeEnvironment?.version ?? ('common.default' | translate) }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="organization">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'tables.admin-tables.organizations.columns.organization' | translate }}</th>
      <td mat-cell *matCellDef="let element; table: table">{{ element.organization?.name }}</td>
    </ng-container>

    <ng-container matColumnDef="organizationType">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'tables.admin-tables.organizations.columns.organizationType' | translate }}</th>
      <td mat-cell *matCellDef="let element; table: table">{{ element.environment.organizationType | organizationType }}</td>
    </ng-container>

    <ng-container matColumnDef="associatedPartner">
      <th mat-header-cell *matHeaderCellDef>{{ 'common.associated_partner' | translate }}</th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-icon [value]="element.associatedPartner"></app-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="theme">
      <th mat-header-cell *matHeaderCellDef>{{ 'tables.admin-tables.organizations.columns.theme' | translate }}</th>
      <td mat-cell *matCellDef="let element; table: table">
        <app-icon [value]="element.environment.theme"></app-icon>
      </td>
    </ng-container>

    <!-- no longer used -->
    <!-- <ng-container matColumnDef="environment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'tables.admin-tables.organizations.columns.environment' | translate }}</th>
      <td mat-cell *matCellDef="let element; table: table">{{ element.computeEnvironment?.environment ?? 'Default' }}</td>
    </ng-container> -->

    <ng-container matColumnDef="divisions">
      <th mat-header-cell *matHeaderCellDef>{{ 'tables.admin-tables.organizations.columns.divisions' | translate }}</th>
      <td mat-cell *matCellDef="let element; table: table">{{ element.divisions?.length ?? 0 }}</td>
    </ng-container>

    <ng-container matColumnDef="identifiers">
      <th mat-header-cell *matHeaderCellDef>{{ 'tables.admin-tables.organizations.columns.identifiers' | translate }}</th>
      <td mat-cell *matCellDef="let element; table: table">{{ element.identifiers.length }}</td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'common.created_at' | translate }}</th>
      <td mat-cell *matCellDef="let element; table: table">
        <span [matTooltip]="element.createdAt | localeDate">
          {{ now$ | async | durationSince:element.createdAt | capitalizeFirst }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef class="shrink"></th>
      <td mat-cell *matCellDef="let element; table: table" class="shrink">
        <app-table-action-buttons [element]="element" [buttons]="actionButtons"></app-table-action-buttons>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns(); sticky: true"></tr>

    <tr mat-row *matRowDef="let row; columns: columns();"></tr>

  </table>
</div>

@if (showPaginator()) {
  <mat-paginator
    showFirstLastButtons
    [length]="dataSource ? (dataSource.totNumDocs$ | async) : 0"
    [pageIndex]="0"
    [pageSize]="pageSize()"
    [pageSizeOptions]="pageSizes()">
  </mat-paginator>
}